import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import ComponentApi from '../../components/ComponentApi';
import ReactPlayground from '../../components/ReactPlayground';
import ButtonGroupBasic from '../../examples/ButtonGroup/Basic';
import ButtonGroupNested from '../../examples/ButtonGroup/Nested';
import ButtonGroupSizes from '../../examples/ButtonGroup/Sizes';
import ButtonToolbar from '../../examples/ButtonGroup/Toolbar';
import ButtonToolbarBasic from '../../examples/ButtonGroup/ToolbarBasic';
import ButtonGroupVertical from '../../examples/ButtonGroup/Vertical';
export const query = graphql`
  query ButtonGroupQuery {
    ButtonGroup: componentMetadata(displayName: { eq: "ButtonGroup" }) {
      displayName
      ...ComponentApi_metadata
    }
    ButtonToolbar: componentMetadata(displayName: { eq: "ButtonToolbar" }) {
      displayName
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "button-groups"
    }}>{`Button groups`}</h1>
    <p className="lead">
  Group a series of buttons together on a single line with the button
  group.
    </p>
    <h2 {...{
      "id": "basic-example"
    }}>{`Basic example`}</h2>
    <p>{`Wrap a series of `}<inlineCode parentName="p">{`<Button>`}</inlineCode>{`s in a `}<inlineCode parentName="p">{`<ButtonGroup>`}</inlineCode>{`.`}</p>
    <ReactPlayground codeText={ButtonGroupBasic} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "button-toolbar"
    }}>{`Button toolbar`}</h2>
    <p>{`Combine sets of  `}<inlineCode parentName="p">{`<ButtonGroup>`}</inlineCode>{`s into a `}<inlineCode parentName="p">{`<ButtonToolbar>`}</inlineCode>{` for more complex components.`}</p>
    <ReactPlayground codeText={ButtonToolbarBasic} mdxType="ReactPlayground" />
    <p>{`Feel free to mix input groups with button groups in your toolbars.
Similar to the example above, you’ll likely need some utilities though
to space things properly.`}</p>
    <ReactPlayground codeText={ButtonToolbar} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "sizing"
    }}>{`Sizing`}</h2>
    <p>{`Instead of applying button sizing props to every button in a group, just
add  `}<inlineCode parentName="p">{`size `}</inlineCode>{` prop to the  `}<inlineCode parentName="p">{`<ButtonGroup>`}</inlineCode>{`.`}</p>
    <ReactPlayground codeText={ButtonGroupSizes} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "nesting"
    }}>{`Nesting`}</h2>
    <p>{`You can place other button types within the
`}<inlineCode parentName="p">{`<ButtonGroup>`}</inlineCode>{` like `}<inlineCode parentName="p">{`<DropdownButton>`}</inlineCode>{`s.`}</p>
    <ReactPlayground codeText={ButtonGroupNested} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "vertical-variation"
    }}>{`Vertical variation`}</h2>
    <p>{`Make a set of buttons appear vertically stacked rather than
horizontally, by adding `}<inlineCode parentName="p">{`vertical`}</inlineCode>{` to the `}<inlineCode parentName="p">{`<ButtonGroup>`}</inlineCode>{`.
`}<strong parentName="p">{`Split button dropdowns are not supported here.`}</strong></p>
    <ReactPlayground codeText={ButtonGroupVertical} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.ButtonGroup} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.ButtonToolbar} mdxType="ComponentApi" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      